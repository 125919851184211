<template>
  <div style="min-height: 125px">
    <b-card style="min-height: 125px">
      <filter-swapper :trigger="selectedRows.length === 0" :buttons="buttons" :controlHeightButtons="controlHeight">
        <template #slot1>
          <form-render :form.sync="formSelect" :key="keyFormRender" :fields="fieldsSelect" @send="filterList" :buttonSend="buttonSend" ref="formFilter"
            containerButtonsClass="col-sm-12 col-md-7 col-lg-4 col-xl-3 container-button mt-2" class="mb-2">
            <template #buttons>
              <b-dropdown class="ml-1" v-b-tooltip.hover :title="$t('Más opciones')" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="light"> 
                <template #button-content><feather-icon icon="SettingsIcon"/></template>
                <b-dropdown-item @click="cleanFilter">{{$t('Limpiar filtros')}}</b-dropdown-item>
                <b-dropdown-item v-b-modal.modalFilter :disabled="disabled">{{$t('Búsqueda avanzada')}}</b-dropdown-item>
              </b-dropdown>
            </template>
          </form-render>
        </template>
      </filter-swapper>
      <div v-if="loading.second && rows.length===0" class="d-flex flex-column justify-content-center align-items-center" style="height: 470px">
        <div>
          <feather-icon icon="FileTextIcon" size="52" style="color:#B9B9C7"/>
        </div>
        <div class="text-wrap text-center my-1" style="width:36rem;font-size:14px;color:#B9B9C7">
          Utiliza los selectores disponibles en la parte superior
          para realizar una búsqueda eficiente y acceder a la información deseada.
        </div>
      </div>
      <div v-show="!loading.total" class="table-listlabels">
        <table-render
          :schema.sync="schema"
          :rows="rows"
          :actions="actions"
          :loading="loading.deliveries"
          :stickyHeader="`calc(100vh - 2.5rem)`"
          id="listDeliveriesPrinter"
          :tableClass="'with-scroll'"
          :striped="true"
          :showCheckboxes="true"
          :selectedRows.sync="selectedRows"
        >
        </table-render>
        <pagination :pagination="pagination" :noDigits="false" :showSize="true"/>
      </div>
      <div class="table-render-skeleton mt-2" v-if="loading.deliveries">
        <b-skeleton-table :rows="10" :columns="schema.length" :table-props="{}" />
      </div>
    </b-card>
    <modal-filter
    id="modalFilter"
    :options="mkcOption"
    @advancedFilter="advancedFilter"
    ></modal-filter>
    <modal-type-print
    id="modalTypePrint"
    @saveSummaries="saveSummaries"
    :deliveries="this.selectedRows"
    ></modal-type-print>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalFilter from './ModalFilter.vue'
import ModalTypePrint from './ModalTypePrint.vue'
export default {
  components: { ModalFilter, ModalTypePrint },
  data() {
    return {
      controlHeight: { class: 'row mb-2 spacing-label-field'},
      buttonSend: {title: 'Buscar', icon: 'SearchIcon', color: 'warning'},
      buttons: [],
      keyFormRender: 0,
      fieldsSelect: [],
      formSelect: {},
      shipper_id: null,
      schema: [],
      rows: [],
      actions: [],
      selectedRows: [],
      mkcOption: [],
      filters:{},
      pagination: {
        page: 1,
        total: 0,
        limit: 20
      },
      loading: {
        deliveries: false,
        total: true,
        second: true
      }
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      organizations: 'getOrganizations',
      shippersByOrganization: 'getShippersByOrganization',
      deliveries: 'getPrinterDeliveries',
      marketcenters: 'getMarketcenters',
      generalLoading: 'getLoading',
      document: 'getPrintSummaries'
    }),
    disabled() {
      if (this.shipper_id) return false
      return true
    }
  },
  watch: {
    shipper_id(value) {
      if (value) this.getMarketcenters()
    },
    marketcenters(val) {
      this.mkcOption = val?.map((op) => ({ id: op.code, text: op.name })) || []
    },
    deliveries() {
      this.pagination.total = this.deliveries?.total_pages * this.deliveries?.limit ?? 0
      this.rows = this.deliveries?.deliveries?.map((delivery) => {
        return {
          id: delivery?.santiago_identifier,
          commerce: delivery?.commerce,
          status: delivery?.status[delivery?.status?.length - 1]?.name,
          reference_2: delivery?.reference_2,
          tracking_number: delivery?.tracking_number,
          marketplace: delivery?.marketplace,
          place: this.setPlace(delivery?.destination?.place),
          created_at: this.$options.filters.dbDateUTCToGMTlocal(delivery?.created_at)
        }
      })
      this.loading.second = false
    },
    mySession() {
      if (this.mySession.id) this.setInitialData()
    },
    organizations () {
      this.setSelectOptions('organization_id', { options: this.organizations.rows })
    },
    shippersByOrganization () {
      const shipperOptions = this.shippersByOrganization.map((shipper) => ({...shipper, name: shipper.name1, text: shipper.name1 }))
      this.setSelectOptions('shipper_id', { options: shipperOptions })
    },
    generalLoading: {
      handler () {
        this.loading.deliveries = !!this.generalLoading.getPrinterDeliveries

        this.setSkeletonField('shipper_id', !!this.generalLoading.getShippersByOrganization)
      },
      deep: true
    },
    loading: {
      handler () {
        const prevTotal = this.loading.total
        this.loading.total = !Object.keys(this.loading).filter(key => key !== 'total').every(key => !this.loading[key])
        if (prevTotal !== this.loading.total) this.keyFormRender++
      },
      deep: true
    },
    'pagination.page' () {
      this.filterList()
    },
    'pagination.limit' () {
      this.filterList()
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      if (['admin'].includes(this.$session.get('cas_user').role)) {
        this.fieldsSelect = [
          {fieldType: 'FieldSelect', name: 'organization_id', label: 'eCommerce/Marketplace', useLabel: true, clearable: true, change: this.changeOrganization, validation: 'required', persistSearch: true, searchOnType: { fx: e => this.getOrganizationsFetch(e), nChars: 3, debounce: 600 }, options: []},
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Nombre de la empresa', clearable: true, dependency: 'organization_id', change: this.changeShipper, validation: 'required' }
        ]
      } else if (this.$session.get('cas_user').role === 'marketplace') {
        this.loading.first = false
        this.organizationId = this.$session.get('cas_user').organization.id
        this.fieldsSelect = [
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Nombre de la empresa', clearable: true, change: this.changeShipper, validation: 'required' }
        ]
        this.changeOrganization('Organization', this.$session.get('cas_user').organization)
      } else {
        this.loading.first = false
        this.shipper_id = this.$session.get('cas_user')?.shipper?.id
        this.filterList()
      }

      this.buttons = [
        { name: 'save', text: 'Crear manifiesto', color: 'primary', icon: 'PrinterIcon', action: this.openModalTypePrint}
      ]

      this.schema = [
        {label: 'Nº de orden', key: 'id'},
        {label: 'Comercio', key: 'commerce'},
        {label: 'Estado', key: 'status', sortable: true},
        {label: 'Referencia 2', key: 'reference_2', sortable: true},
        {label: 'Orden_de_transporte', key: 'tracking_number', sortable: true},
        {label: 'Marketplace', key: 'marketplace', sortable: true},
        {label: 'Place_level3', key: 'place', sortable: true},
        {label: 'Fecha de creacion', key: 'created_at', sortable: true}
      ]
    },
    getOrganizationsFetch(name) {
      const data = { 
        name: 'getOrganizations',
        queryParams: {name, simplified: true }
      }
      this.$store.dispatch('fetchService', data)
    },
    openModalTypePrint() {
      this.$bvModal.show('modalTypePrint')
    },
    filterList() {
      this.loading.second = false
      const shipper_id = this.shipper_id
      const data = {
        name: 'getPrinterDeliveries',
        params: {
          shipper_id,
          page: this.pagination.page,
          limit: this.pagination.limit
        }
      }
      this.$store.dispatch('fetchService', data)
    },
    changeOrganization (name, value) {
      this.organization_id = value.id
      this.getOrganizationByShipper(value.id)
    },
    getOrganizationByShipper(organization_id) {
      const data = { 
        name: 'getShippersByOrganization',
        queryParams: {page: 1, paginate_by: 99999 },
        params: { organization_id },
        onError: () => {
          this.setSelectOptions('shipper_id', { options: [] })
          this.setSkeletonField('shipper_id', false)
        },
        hideAlert: true
      }
      this.$store.dispatch('fetchService', data)
    },
    getMarketcenters() {
      const data = {
        name: 'getMarketcenters',
        params: { shipper_id: this.shipper_id }
      }
      this.$store.dispatch('fetchService', data)
    },
    cleanFilter (e) {
      this.formSelect = {}
    },
    setSelectOptions (name, { options }) {
      const index = this.fieldsSelect.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fieldsSelect[index].options = options
        if (!['organization_id'].includes(name)) this.keyFormRender++
      }
    },
    changeShipper(_, value) {
      this.shipper_id = value.id
    },
    saveSummaries(type) {
      const queryParams = { deliveries:  this.selectedRows }
      if (type) queryParams.type = type
      const data = {
        name: 'postPrintSummaries',
        params: {shipper_id: this.shipper_id },
        queryParams,
        onSuccess: () => { this.filterList(); this.selectedRows = []; this.$bvModal.hide('modalTypePrint') }
      }
      this.$store.dispatch('fetchService', data)
    },
    advancedFilter(form) {
      this.loading.second = false
      this.$bvModal.hide('modalFilter')
      if (form?.date) form.date = this.$options.filters.moment(form?.date, 'YYYY-MM-DD')
      const shipper_id = this.shipper_id
      if (!!form['marketceter-id']) form['marketceter-id'] = form['marketceter-id'].id
      const data = {
        name: 'getPrinterDeliveries',
        params: {
          shipper_id,
          page: this.pagination.page,
          limit: this.pagination.limit
        },
        queryParams : {
          ...form
        }
      }
      this.$store.dispatch('fetchService', data)
    },
    setPlace(place) {
      switch (place.level1) {
      case 'Chile':
        return place?.level3
      case 'Peru':
        return place?.level3
      case 'Colombia':
        return place?.level3
      default:
        return place?.level4 ?? null
      }
    },
    setSkeletonField(name, value) {
      const index = this.fieldsSelect.findIndex(el => el.name === name)
      if (index !== -1) this.fieldsSelect[index].useSkeleton = value
    }
  }
}
</script>

<style>
.table-listlabels .table-render-skeleton {
  overflow-x: hidden !important;
}
</style>