<template>
  <b-modal
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Previsualizador')"
    size="xl"
    no-close-on-esc
    no-close-on-backdrop
    centered
    no-footer
    hide-footer
  >
    <b-container>
      <b-row class="text-center">
        <b-col>
          <b-button :variant="summary" @click="viewSummary">{{$t('Manifiesto')}}</b-button>
        </b-col>
        <b-col>
          <b-button :variant="label" @click="viewLabel">Etiquetas</b-button>
        </b-col>
      </b-row>
    </b-container>
      
    <div v-if="src" class="mt-2">
      <iframe width="100%" height="700" :src="src"></iframe>
    </div>
    <div class="m-1">
      * Si no puedes previsualizar el PDF haz <b-link :href="src" target="_black">click aquí</b-link>
    </div>
  </b-modal>
</template>
<script>
import { BContainer, BLink } from 'bootstrap-vue'
export default {
  components: { BContainer, BLink },
  props: {
    summary_url: { type: String },
    labels_url: { type: String }
  },
  data() {
    return {
      view: true,
      src: ''
    }
  },
  computed: {
    summary() {
      if (!this.view) return 'secondary'
      return 'warning'
    },
    label() {
      if (this.view) return 'secondary'
      return 'warning'
    }
  },
  watch: {
    summary_url() {
      this.viewSummary()
    }
  },
  methods: {
    viewSummary() {
      this.src = this.summary_url ?? ''
      this.view = true
    },
    viewLabel() {
      this.src = this.labels_url ?? ''
      this.view = false
    }
  }
}
</script>