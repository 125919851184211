<template>
  <div>
    <b-card>
      <filter-swapper :buttons="buttons" :controlHeightButtons="controlHeight">
        <template #slot1>
          <form-render :form.sync="formSelect" :key="keyFormRender" :fields="fieldsSelect" @send="filterList" :buttonSend="buttonSend" ref="formFilter"
            containerButtonsClass="col-sm-12 col-md-7 col-lg-4 col-xl-3 container-button mt-2" class="mb-2">
            <template #buttons>
              <b-button variant="outline-light" v-b-tooltip.hover title="Limpiar filtros" class="ml-1" @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/></b-button>
            </template>
          </form-render>
        </template>
      </filter-swapper>
      <div v-if="loading.second && rows.length===0" class="d-flex flex-column justify-content-center align-items-center" style="height: 470px">
        <div>
          <feather-icon icon="FileTextIcon" size="52" style="color:#B9B9C7"/>
        </div>
        <div class="text-wrap text-center my-1" style="width:36rem;font-size:14px;color:#B9B9C7">
          Utiliza los selectores disponibles en la parte superior
          para realizar una búsqueda eficiente y acceder a la información deseada.
        </div>
      </div>
      <div v-show="!loading.total" class="table-listsummaries">
        <table-render
          :schema.sync="schema"
          :rows="rows"
          :actions="actions"
          :loading="loading.summaries"
          :stickyHeader="`calc(100vh - 2.5rem)`"
          id="listSummaries"
          :tableClass="'with-scroll'"
          :striped="true"
          :showCheckboxes="false"
        >
        </table-render>
        <pagination :pagination="pagination" :noDigits="false" :showSize="true"/>
      </div>
      <div class="table-render-skeleton mt-2" v-if="loading.summaries">
        <b-skeleton-table :rows="7" :columns="7" :table-props="{}" />
      </div>
    </b-card>
    <modal-summaries
    id="mktSummariesModal"
    :summary="selectRow"
    :shipper_id="shipper_id"
    @filterList="filterList"
    >
    </modal-summaries>
    <modal-document
    id="printerDocumentModal"
    :summary_url="docs.summary_url"
    :labels_url="docs.labels_url"
    >
    </modal-document>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalDocument from './ModalDocument.vue'
import ModalSummaries from './ModalSummaries.vue'
export default {
  components: { ModalSummaries, ModalDocument },
  data() {
    return {
      controlHeight: { class: 'row mb-2 spacing-label-field'},
      buttonSend: {title: 'Buscar', icon: 'SearchIcon', color: 'warning'},
      buttons: [],
      keyFormRender: 0,
      fieldsSelect: [],
      formSelect: {},
      shipper_id: null,
      schema: [],
      rows: [],
      actions: [],
      selectRow: {},
      docs: {summary_url: '', labels_url: ''},
      pagination: {
        page: 1,
        total: 0,
        limit: 20
      },
      loading: {
        summaries: false,
        total: true,
        second: true
      }
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      organizations: 'getOrganizations',
      shippersByOrganization: 'getShippersByOrganization',
      summaries: 'getSummaries',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    summaries() {
      this.rows = this.summaries?.summaries?.map((summary) => {
        return {
          id: summary?.id,
          seller_name: summary?.seller_name,
          amount: summary?.deliveries?.length,
          status: summary?.status,
          created_at: summary?.created_at,
          updated_at: summary?.updated_at
        }
      })
      this.loading.second = false
    },
    mySession() {
      if (this.mySession.id) this.setInitialData()
    },
    organizations () {
      this.setSelectOptions('organization_id', { options: this.organizations.rows })
    },
    shippersByOrganization () {
      const shipperOptions = this.shippersByOrganization.map((shipper) => ({...shipper, name: shipper.name1, text: shipper.name1 }))
      this.setSelectOptions('shipper_id', { options: shipperOptions })
    },
    generalLoading: {
      handler () {
        this.loading.summaries = !!this.generalLoading.getSummaries

        this.setSkeletonField('shipper_id', !!this.generalLoading.getShippersByOrganization)
      },
      deep: true
    },
    loading: {
      handler () {
        const prevTotal = this.loading.total
        this.loading.total = !Object.keys(this.loading).filter(key => key !== 'total').every(key => !this.loading[key])
        if (prevTotal !== this.loading.total) this.keyFormRender++
      },
      deep: true
    },
    'pagination.page' () {
      this.filterList()
    },
    'pagination.limit' () {
      if (this.pagination.page === 1) this.filterList()
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      if (['admin', 'superadmin'].includes(this.$session.get('cas_user').role)) {
        this.fieldsSelect = [
          {fieldType: 'FieldSelect', name: 'organization_id', label: 'eCommerce/Marketplace', useLabel: true, clearable: true, change: this.changeOrganization, validation: 'required', persistSearch: true, searchOnType: { fx: e => this.getOrganizationsFetch(e), nChars: 3, debounce: 600 }, options: []},
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Nombre de la empresa', clearable: true, dependency: 'organization_id', change: this.changeShipper, validation: 'required' }
        ]
      } else if (this.$session.get('cas_user').role === 'marketplace') {
        this.organizationId = this.$session.get('cas_user').organization.id
        this.fieldsSelect = [
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Nombre de la empresa', clearable: true, change: this.changeShipper, validation: 'required' }
        ]
        this.changeOrganization('organization_id', this.$session.get('cas_user').organization)
      } else {
        this.shipper_id = this.$session.get('cas_user')?.shipper?.id
        this.filterList()
      }

      this.schema = [
        {label: 'Nº manifiesto', key: 'id'},
        {label: 'Comercio', key: 'seller_name', sortable: true},
        {label: 'Envios', key: 'amount', sortable: true, class: ['text-center']},
        {label: 'Estado', key: 'status', sortable: true},
        {label: 'Fecha creación', key: 'created_at', sortable: true},
        {label: 'Fecha modificación', key: 'updated_at', sortable: true},
        {label: 'Acciones', key: 'actions', class: ['text-center']}
      ]

      this.actions = [
        {action: id => this.openModal(id), icon: 'FileTextIcon', color: 'primary', text: 'Ver manifiesto' }
      ]
    },
    getOrganizationsFetch(name) {
      const data = { 
        name: 'getOrganizations',
        queryParams: {name, simplified: true }
      }
      this.$store.dispatch('fetchService', data)
    },
    filterList(form) {
      this.loading.second = false
      const shipper_id = form?.shipper_id?.id ?? this.shipper_id
      const data = {
        name: 'getSummaries',
        params: {shipper_id, limit: this.pagination?.limit, page: this.pagination?.page}
      }
      this.$store.dispatch('fetchService', data)
    },
    changeOrganization (name, value) {
      this.organization_id = value.id
      this.getOrganizationByShipper(value.id)
    },
    getOrganizationByShipper(organization_id) {
      const data = { 
        name: 'getShippersByOrganization',
        queryParams: {page: 1, paginate_by: 99999 },
        params: { organization_id },
        onError: () => {
          this.setSelectOptions('shipper_id', { options: [] })
          this.setSkeletonField('shipper_id', false)
        },
        hideAlert: true
      }
      this.$store.dispatch('fetchService', data)
    },
    cleanFilter (e) {
      this.formSelect = {}
    },
    setSelectOptions (name, { options }) {
      const index = this.fieldsSelect.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fieldsSelect[index].options = options
        if (!['organization_id'].includes(name)) this.keyFormRender++
      }
    },
    openModal(id) {
      const summary = this.summaries?.summaries?.find((r) => r.id === id)
      if (summary.status !== 'CLOSED') return this.openDeliveriesModal(summary)
      return this.openDocumentModal(summary)
    },
    openDeliveriesModal(summary) {
      this.selectRow = {
        deliveries: summary?.deliveries,
        summary_id: summary?.id
      }
      this.$bvModal.show('mktSummariesModal')
    },
    openDocumentModal(summary) {
      const { summary_url, labels_url } = summary
      this.docs = {summary_url, labels_url}
      this.$bvModal.show('printerDocumentModal')
    },
    changeShipper(_, value) {
      this.shipper_id = value.id
    },
    setSkeletonField(name, value) {
      const index = this.fieldsSelect.findIndex(el => el.name === name)
      if (index !== -1) this.fieldsSelect[index].useSkeleton = value
    }
  }
}
</script>

<style>
.table-listsummaries .table-render-skeleton {
  overflow-x: hidden !important;
}
</style>