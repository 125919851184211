<template>
  <b-modal
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Tipo de impresión')"
    no-close-on-esc
    no-close-on-backdrop
    centered
    no-footer
    hide-footer
  >
    <!-- <b-container> -->
      <div class="p-2">
        <b-alert class="p-2" variant="warning" show>Total de envíos seleccionados: <strong>{{ deliveries.length }}</strong></b-alert>
        <b-row class="text-center">
            <b-col>
            <b-button variant="warning" :disabled="loading.save" @click.prevent="onSave()">Zebra</b-button>
            </b-col>
            <b-col>
            <b-button variant="warning" :disabled="loading.save" @click.prevent="onSave('carta')">Hoja carta</b-button>
            </b-col>
            <b-col>
            <b-button variant="warning" :disabled="loading.save" @click.prevent="onSave('zpl')">ZPL</b-button>
            </b-col>
        </b-row>
      </div>
    <!-- </b-container> -->
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    deliveries: { type: Array }
  },
  data() {
    return {
      loading: {
        save: false
      }
    }
  },
  computed: {
    ...mapGetters({
      generalLoading: 'getLoading'
    })
  },
  watch: {
    generalLoading: {
      handler () {
        this.loading.save = !!this.generalLoading.postPrintSummaries
      },
      deep: true
    }
  },
  methods: {
    onSave(type) {
      this.$emit('saveSummaries', type)
    }
  }
    
}
</script>