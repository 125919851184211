<template>
  <b-modal
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('Búsqueda avanzada')"
    :ok-title="$t('Filtrar')"
    size="lg"
    ok-variant="warning"
    ok-only
    no-close-on-esc
    no-close-on-backdrop
    centered
    no-footer
    @ok="ok"
  >
     <form-render
      :form.sync="form"
      :fields="fields"
      @send="onAccept"
      containerButtonsClass="col-sm-12"
      ref="formRenderModalFilter"
    >
    </form-render>
  </b-modal>
</template>
<script>
export default {
  props: {
    options: { type: Array, default() { return [] } }
  },
  data() {
    return {
      fields: [
        { fieldType: 'FieldDatepicker', label: 'Fecha de búsqueda', name: 'date', range: false, clearable: true, containerClass: 'col-md-6 container-info col-sm-12'},
        { fieldType: 'FieldInput', label: 'Nº de orden', name: 'order-number', containerClass: 'col-md-6 container-info col-sm-12'},
        { fieldType: 'FieldInput', label: 'Orden_de_transporte', name: 'tracking-number', containerClass: 'col-md-6 container-info col-sm-12'},
        { fieldType: 'FieldSelect', label: 'Marketcenter', name: 'marketceter-id', containerClass: 'col-md-6 container-info col-sm-12' },
        { fieldType: 'FieldInput', label: 'Place_level3', name: 'destination', containerClass: 'col-md-6 container-info col-sm-12'},
        { fieldType: 'FieldInput', label: 'Referencia 2', name: 'reference-2', containerClass: 'col-md-6 container-info col-sm-12'}
      ],
      form: {}
    }
  },
  watch: {
    options(val) {
      if (val.length > 0) this.fields[this.fields.findIndex((field) => field.name === 'marketceter-id')].options = val
    }
  },
  methods : {
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderModalFilter.checkForm()
    },
    onAccept(data) {
      this.$emit('advancedFilter', data)
    }
  }
}
</script>
